import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/base/rechargeRecordWater/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/base/rechargeRecordWater/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/base/rechargeRecordWater/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/base/rechargeRecordWater/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/rechargeRecordWater/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/base/rechargeRecordWater/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/base/rechargeRecordWater/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function queryRoomMessage(clientId){
  return request.get(constant.serverUrl + "/base/rechargeRecordWater/queryRoomMessage/" + clientId);
}

function saveRecord(idList){
  return request.post(constant.serverUrl + "/base/rechargeRecordWater/saveRecord",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
  pageList,create,edit,add,update,remove,batchRemove,saveRecord,queryRoomMessage
}